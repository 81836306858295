.col {
  
    padding:0px;
  }

  .navbar-style-wedding{
    background-color : white;
    border-bottom: 1px solid lightgray;
}
body{
  width:100%;
}