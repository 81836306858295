#firstPic{
    text-align:center;
    width:auto;
    height:700px;
    /* margin-bottom:10vh; */
}
.display-date{
    color : #4b3751;
    display:flex;
    padding:10px;
    margin:10px;
    height:6vw;
    width:6vw;
    /* object-position: bottom center; */
    border-top :1px solid #4b3751;
    border-bottom :1px solid #4b3751;
    
    background:rgba(255, 255, 255, 0.4);
    border-radius: 100% 100% 100% 100%;
    
}
.display-date-p {
    font-size: large; 
    font-family: adage-script-jf, sans-serif;
}
.display-date-pos{
    position:absolute;
    /* top:100%; */
    /* bottom: 30px; */
    /* top:150%;
    left:25%;
    right:25%; */
    bottom: 150px;
    z-index:1;
    text-align:center;
    
    font-size: 25px; 
}

@media (max-width:950px){
    .display-date{
        margin:5px;
        padding:5px;
        height:8vw;
        width: 8vw;
    }
}
@media (max-width:490px) {
    #firstPic{
        text-align:center;
        width:100%;
        height:auto;
        margin-bottom:10vh;
    }
    .display-date{
        color : #4b3751;
        display:flex;
        /* padding:5px;
        margin:10px; */
        height:18vw;
        width:18vw;
        border-top :1px solid #4b3751;
        border-bottom :1px solid #4b3751;
        border-radius: 100% 100% 100% 100%;
    
    }
    .display-date-p {
        font-size: small; 
        font-family: adage-script-jf, sans-serif;
        text-align:center;
    }
    .display-date-pos{
        position:absolute;
        top:75%;
        left:0%;
        right:0%;
        z-index:1;
        /* width:100%; */
        font-size: medium; 
    }
}