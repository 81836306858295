

.navbar-style-wedding{
    /* background-color: black; */
    /* border-top:1px solid black; */
    /* border-right:1px solid black;
    border-left:1px solid black; */
}
@media (max-width:480px) {
    .navbar-style-wedding-items{

        border-bottom : 1px solid black;
    }
    .navbar-style-wedding-bar{
        border-top:1px solid black;
        /* border-right:1px solid black;
        border-left:1px solid black; */
    }
}