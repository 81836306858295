.row-form {
    margin-top:5px;
    margin-bottom:5px;
}
strong{
    text-decoration: underline;
}
a {
    text-decoration : underline;
    color :black;
}
a:hover {
    text-decoration : none;
    color:rgb(227, 161, 161)
}
.map-name{
    color:rgb(227, 161, 161);
}
.map-name:hover{
    color:rgb(238, 185, 185);
}

.info{
        font-family: 'Gill Sans Nova', 'Gill Sans MT', sans-serif;
        font-size: large;
        font-style: normal;
        text-align:center;
        margin-bottom:15px;
        margin-top:15px;
}