
.famille{
    /* font-family: palatino, sans-serif; */
    font-family: adage-script-jf, sans-serif;
    font-size: xx-large;
    font-style: normal;
    margin-bottom:50px;
    
}
.titre{
    font-family: adage-script-jf, sans-serif;
    font-size: 100px;
    color:rgb(227, 161, 161);
    /* color:rgb(227, 161, 161); */
    font-style: normal;
    margin-top:20px;
    margin-bottom:40px;
}
/* .titre-henne{
    font-family: adage-script-jf, sans-serif;
    font-size: 50px;
    /* color:rgb(227, 161, 161); 
    color:rgb(0,0,0);
    font-style: normal;
    margin-top:20px;
    margin-bottom:40px;
} */
.importante-oui{
    font-family: adage-script-jf, sans-serif;
    /* font-size: xxx-large; */
    
    /* font-family: krul, sans-serif; */
    font-size: 50px;
    font-style: italic;
    /* color:rgb(1, 1, 1); */
    color:rgb(227, 161, 161);
    font-style: normal;

}
.hebreux-phrase{
    margin-bottom:30px;
    width:15vw;
    height:auto;
}


.col {
  
    padding:0px;
}

.logo{
    text-align:center;
    margin-bottom:15px;
}

.solenel{
    text-align: center;
    font-style: italic;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* font-family: adage-script-jf, sans-serif; */
    font-size: small;
    margin-bottom:15px;
    
}
.solenel-honneur{
    text-align: center;
    font-style: italic;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* font-family: adage-script-jf, sans-serif; */
    font-size: 20px;
    margin-bottom:15px;
    
}
.solenel-code{
    text-align: center;
    font-style: italic ;
    text-decoration: underline;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    /* font-family: adage-script-jf, sans-serif; */
    font-size: 20px;
    margin-bottom:15px;
    
}

.message{
    font-family: 'Gill Sans Nova', 'Gill Sans MT', sans-serif;
    font-size: large;
    font-style: normal;
    text-align:center;
    margin-bottom:15px;
    
}

.infos{
    font-family: 'Gill Sans Nova', 'Gill Sans MT', sans-serif;
    font-size: x-large;
    font-weight: 200;
    text-align:center;
    margin-bottom:15px;
}
.marie{
    font-family: "krul", sans-serif !important;
    font-style: normal;
    font-size: 100px;
    margin-bottom:15px;/* 
    font-size: 50px;
    font-family: "dunhill-script", sans-serif;
    font-weight: 600;
    font-style: normal; */
}
.maman{
    font-family: "krul", sans-serif;
    font-size: 40px;
    font-style: normal;
    margin-bottom:15px;
}



.btn{
    /* color:rgb(227, 161, 161); */
    background-color: rgb(227, 161, 161);
    border :rgb(227, 161, 161);
}
.btn-primary{
    /* color:rgb(227, 161, 161); */
    background-color: rgb(227, 161, 161);
    border :rgb(227, 161, 161);
}

.in-between-pic{
    height:500px;
    width:auto;
}


@media (max-width:480px){
    .titre{
        font-family: adage-script-jf, sans-serif;
        font-size: 50px;
        /* color:rgb(227, 161, 161); */
        color:rgb(227, 161, 161);
        font-style: normal;
        margin-top:20px;
        margin-bottom:40px;
    }
    .famille{
        /* font-family: palatino, sans-serif; */
        font-family: adage-script-jf, sans-serif;
        font-size: large;
        font-style: normal;
        margin-bottom:10px;
        
    }
    .hebreux-phrase{
        margin-bottom:30px;
        width:15vh;
        height:auto;
    } 
    .marie{
        font-size: 50px;
    }
}